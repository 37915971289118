import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { AboutUsComponent } from './components/staticPage/about-us/about-us.component';
import { TermsConditionsComponent } from './components/staticPage/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/staticPage/privacy-policy/privacy-policy.component';
import { TermsConditionSupplierComponent } from './components/staticPage/terms-condition-supplier/terms-condition-supplier.component';
import { KycComponent } from './components/kyc/kyc/kyc.component';
import { KycPageComponent } from './components/staticPage/kyc/kyc-page/kyc-page.component';

const routes: Routes = [
  { path: '', redirectTo: '/mobipetz-admin/dashboard', pathMatch: 'full' },
  { path: 'mobipetz-admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule),canActivate: [AuthGuard]  },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-&-conditions', component: TermsConditionsComponent },
  { path: 'terms-&-conditions-supplier', component: TermsConditionSupplierComponent },
  { path: 'kyc', component: KycComponent},
  { path: 'kyc_process', component: KycPageComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
