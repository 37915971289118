import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { error } from 'console';
import { ModalService } from 'src/app/modal/modal.service';
import { LoginPayload } from 'src/app/services/auth/auth.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { Storage_Keys } from 'src/app/services/storage/storage.keys';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  password: boolean;
  loginForm: FormGroup;
  loginFormSubmitted = false;
  loginApiErrorResponse: HttpErrorResponse;
  kycData:any;
  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private storage: StorageService,
    private router: Router,
  ) {
    this.loginForm = new FormGroup({
      "email": new FormControl('', Validators.compose([Validators.required])),
      "password": new FormControl('', Validators.compose([Validators.required])),
    });

  }


  ngOnInit(): void {
   
   }
  showPassword() {
    this.password = !this.password;
  }

  get a() {
    return this.loginForm.controls;
  }

  submit() {
    if (this.loginForm.invalid) {
      this.loginFormSubmitted = true;
      return;
    }
    let payload = new LoginPayload(this.loginForm.value);
    this.commonService.presentSpinner();
    this.authService.login(payload).then((res: any) => {
   
      if (res && res.admindata) {
        this.storage.set(Storage_Keys.admin_data, res.admindata);
        this.storage.set(Storage_Keys.token, res.admindata.JWT_Token[0]);
        switch (res.admindata.role) {
          case "superadmin":
            this.commonService.showSuccessToastMsg(res.message);
            this.router.navigate(['/mobipetz-admin/dashboard']);
            break;
          case "operations":
            this.commonService.showSuccessToastMsg(res.message);
            this.router.navigate(['/mobipetz-admin/customer-listing']);
            break; case "company":
              this.router.navigate(['//mobipetz-admin/Health_&_Wellness']);
            break;
        }
      }
    }).catch((error) => {
      this.loginApiErrorResponse = error;
    }).finally(() => {
      this.commonService.dismissSpinner();

    });
  }



}
