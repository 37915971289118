import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-terms-condition-supplier',
  templateUrl: './terms-condition-supplier.component.html',
  styleUrls: ['./terms-condition-supplier.component.scss']
})
export class TermsConditionSupplierComponent implements OnInit {

  data:any;
  constructor(
    private authService:AuthService
  ) { }

  ngOnInit(): void {
    this.getTermsConditionSupplier();
  }


  getTermsConditionSupplier(){
    this.authService.termsConditionSupplier().then((res)=>{
      if(res){
        this.data=res
      }
      
    })
  }

}
