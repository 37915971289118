import { Injectable } from '@angular/core';
import { Storage_Keys } from './storage.keys';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  id = "mobipetz";

  private savedData: any = {};

  constructor() { }

  private encrypt(data: any) {
    let doubleStrigifiedData = JSON.stringify(JSON.stringify(data));
    let encryptedData = CryptoJS.AES.encrypt(doubleStrigifiedData, this.id).toString();
    return encryptedData;
  }

  private decrypt(encrytedData: any, encrytedDataLocation: string) {
    let data = "";
    if (encrytedData) {
      let bytes = CryptoJS.AES.decrypt(encrytedData, this.id);
      data = bytes.toString(CryptoJS.enc.Utf8);
      if (!data) {
        this.removeItem(encrytedDataLocation)
      }
    }
    return data ? JSON.parse(JSON.parse(data)) : "";
  }

  public getSavedData() {
    this.savedData = {};
    Object.keys(Storage_Keys).forEach(
      (key) => {
        if (key) {
          let data = this.get(Storage_Keys[key])
          if (data) {
            this.savedData[Storage_Keys[key]] = data;
          }
        }
      }
    );

    return this.savedData;
  }

  public set(key: string, data: any) {
    if (!data) {
      return;
    }
    return new Promise((resolve, reject) => {
      let encryptedData = this.encrypt(data);
      resolve(localStorage.setItem(key, encryptedData));
    });
  }

  get(key: string) {
    return this.decrypt(localStorage.getItem(key), key);
  }

  public removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  private clearStorage() {
    localStorage.clear();
  }

  public clearStorageForLogout() {
    return new Promise((resolve, reject) => {
      let count = 0
      Object.keys(Storage_Keys).forEach(
        (key) => {
          // if (key && storage_keys[key] !== storage_keys.lastSelectedLanguage && storage_keys[key] !== storage_keys.rememberMe) {
          this.removeItem(Storage_Keys[key]);
          count++;
          // }
        }
      );
      if (count === Object.keys(Storage_Keys).length) {        
        resolve(true);
      }
    })
  }
}
