import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  data:any;
  constructor(
    private authService:AuthService
  ) { }

  ngOnInit(): void {
    this.getAbout();
  }


  getAbout(){
    this.authService.aboutUs().then((res)=>{
      if(res){
        this.data=res
      }
      
    })
  }

}
