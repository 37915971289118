export class LoginPayload{
    email:string
    password:string
    constructor(data:{email:string,password:string}){
        // super();
        if(data){
            this.email = (data.email || "").trim();
            this.password=(data.password || "").trim();

        }

    }
}