import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { Endpoints } from '../http/http-wrapper/endpoint';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpWrapperService } from '../http/http-wrapper/http-wrapper.service';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  data = new Subject();
  private shareData = new BehaviorSubject<any>(null);
  private dataSubject = new Subject<any>();
  pageRefresh: Subject<boolean> = new Subject<boolean>();

  constructor(
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private router: Router,
    private http: HttpWrapperService,


  ) {

  }

  numberWithDecimal(event) {
    const input = event.target;
    const currentValue = input.value;
    const key = event.key;
    // Check if the first character is a decimal point and the input is empty
    if (currentValue === '' && key === '.') {
      event.preventDefault(); // Prevent the input
    }

    // Allow numeric characters, backspace, and arrow keys
    if (/[\d\.]/.test(key) || event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return true;


      // return true;
    }

    // Prevent all other keys
    event.preventDefault();
    return false;
  }
  presentSpinner() {
    this.spinner.show();
  }

  dismissSpinner() {
    this.spinner.hide();
  }

  showSuccessToastMsg(title?: string, message?: string) {
    this.toast.success(message, title, {
      timeOut: 2000
    });
  }

  showErrorToastMsg(title?: string, message?: string) {
    this.toast.error(message, title, {
      timeOut: 3000
    });
  }

  matchConfirmPassword(formGroup: FormGroup) {
    const password = formGroup.get('newPassword').value;
    const confirmPassword = formGroup.get('confirmPassword').value;

    let returnAble = password === confirmPassword ? null : { notSame: true };

    return returnAble
  }

  setNewInfo(user: any) {
    this.shareData.next(user);
  }
  getNewInfo() {
    return this.shareData.asObservable();
  }


  petlife() {
    return new Promise((resolve, reject) => {
      this.http.get(Endpoints.petLife.list).subscribe(
        (res) => {
          // res.
          resolve(res);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    });
  }

  UploadImage(payload: any) {
    return new Promise((resolve, reject) => {
      this.http.post(Endpoints.admin.uploadDocuments,payload).subscribe(
        (res) => {
          // res.
          resolve(res);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    });
   }


  changeDeliveryDate(id, payload: any) {
    return new Promise((resolve, reject) => {
      this.http.put(Endpoints.date.change(id), payload).subscribe(
        (res) => {
          // res.
          resolve(res);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    });
  }
  orderStatus(payload:any){
    return new Promise((resolve, reject) => {
      this.http.put(Endpoints.Order.OrserStatus, payload).subscribe(
        (res) => {
          // res.
          resolve(res);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        }
      );
    });
  }
  refresh() {
    const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    const prevOSN = this.router.onSameUrlNavigation;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';


    this.router.navigate([this.router.url]);
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = prev;
      this.router.onSameUrlNavigation = prevOSN;
    }, 0);
  }


 kycLink() {
  return new Promise((resolve, reject) => {
    this.http.get(Endpoints.kyc.link).subscribe(
      (res) => {
        // res.
        resolve(res);
      },
      (error: HttpErrorResponse) => {
        reject(error);
      }
    );
  });
}
kycStatus() {
  return new Promise((resolve, reject) => {
    this.http.get(Endpoints.kyc.status).subscribe(
      (res) => {
        // res.
        resolve(res);
      },
      (error: HttpErrorResponse) => {
        reject(error);
      }
    );
  });
}



}

export class Entries {
  from: number = 0;
  to: number = 0;
  total: number = 0;

  constructor(list?: Array<any>, current_page?: number, limit?: number, totalResult?: number) {
    list = list && list.length ? list : [];
    if (!totalResult) {
      totalResult = list.length;
    }
    if (!limit) {
      limit = list.length;
    }
    if (list.length && list.length === totalResult) {
      let pages = {
        1: []
      };
      let arr = [];
      let page = 1;
      for (let i = 0; i < list.length; i++) {
        arr.push(list[i]);
        if (arr.length == limit) {
          pages[page] = arr;
          page += 1;
          arr = [];
        } else {
          if (i == list.length - 1) {
            pages[page] = arr;
            page += 1;
            arr = [];
          }
        }
      }
      list = pages[current_page];
    }
    this.from = limit * (current_page - 1) + 0 + 1;
    this.to = limit * (current_page - 1) + (list.length - 1) + 1;
    this.total = totalResult;
  }





}
