
export const admin = "/admin/v8/";
const frontEnd = "/api/v6/";
const lalamove = "/lalamove/v6/"
export const Endpoints = {
  "auth": {
    "login": `${admin}login`,
    "dashboard": `${admin}/dashboard`,
    "logout": `${admin}/logout`,
    "changePassword": `${admin}/change-password`,
    "resetPassword": `${admin}auth/reset-password`,

  },
  // 
  "db": {
    "dashboard": (startDate: string, endDate: string, period: number) => { return `${admin}dashboard?period=${period}` + (startDate ? `&startDate=${startDate}` : '') + (endDate ? `&endDate=${endDate}` : '') }
  },

  "customer": {
    // "list":"customers",
    "list": `${admin}customers`,

    "status": `${admin}customer`,
    "delete": `${admin}customer`,
    "customerDeatls": (banner_id: string) => { return `${admin}customer/${banner_id}` },
  },
  // 
  "supplier": {
    "list": (page: number, limit: number, search: string, status: string, bearingfilter: string, kycfilter: string) => { return `${admin}suppliers?page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') + (status ? `&status=${status}` : '') + (bearingfilter ? `&bearingfilter=${bearingfilter}` : '') + (kycfilter ? `&kycfilter=${kycfilter}` : '') },
    "status": `${admin}supplier`,
    "delete": `${admin}supplier`,
    "rating": (supplierId: string) => { return `${admin}get-review-rating?supplierId=${supplierId}` },
    "deleteRating": (ratingId: string) => { return `${admin}rating?ratingId=${ratingId}` },
    "kycStatus": `${admin}supplier-kyc`,
    "suppliercommission": `${admin}supplier-commission`,
    "auropayId": `${admin}supplier-auropayId`,

  },
  "supplierService": {
    "list": (page: number, limit: number, search: string, serviceCategoryId: string) => { return `${admin}supplier-service-list?page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') + (serviceCategoryId ? `&serviceCategoryId=${serviceCategoryId}` : '') },
    "detail": (supplierId: string, serviceCategoryId: string) => { return `${admin}service-detail?supplierId=${supplierId}&serviceCategoryId=${serviceCategoryId}` }
    // serviceCategoryId
    // "list": `${admin}supplier-service-list`,
    // "status":"supplier",
    // "delete":"supplier"
  },
  "system": {
    "list": `${admin}banners`,
    "add": `${admin}banner`,
    "delete": (banner_id: string) => { return `${admin}/banner/${banner_id}` },
    "update": (banner_id: string) => { return `${admin}/banner/${banner_id}` },
    "termsConditions": `${admin}content`,
    "termsConditionUpdate": (contentId: string) => { return `${admin}/content?contentId=${contentId}` },
    "faq": `${admin}faqs`,
    "addUpdate": `${admin}faqs`,
    "deleteFaq": (faqid: string) => { return `${admin}/faqs/${faqid}` },
    "addLogistics": `${admin}logistics`,
    "listLogistics": `${admin}logistics`,
    "addDiscount": `${admin}discount`,
    "listDiscount": `${admin}discount`,
    "faqReorder": `${admin}faqs-reorder`

    // "logisticAmount":()
  },
  "wellnes": {
    "product": `${admin}/product?productType=Wellness`,
    "addproduct": `${admin}product`,
    "updateProduct": (productId: string) => { return `${admin}product/${productId}` },
    "updateproductStatus": (productId: string) => { return `${admin}/product-status/${productId}` },
    "uploadProductimage": `${admin}upload-product`,
    "exportFile": (page: number, limit: number, search: string, companyId: string, petCategoryId: string, role: string) => { return `${admin}/downloadOrderExcelsheet?productType=Wellness&page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') + (companyId ? `&companyId=${companyId}` : '') + (petCategoryId ? `&petCategoryId=${petCategoryId}` : '') + (role ? `&role=${role}` : '') }
  },
  "wellnessOrder": {
    "list": `${admin}order?productType=Wellness`,
    // paymentStatus 
    "orderDetails": (orderId: string) => { return `${admin}orderDetails?orderId=${orderId}` },
    "orderCancel": (orderId: string) => { return `${admin}cancel-order/${orderId}` },

  },
  "gourmet": {
    "product": `${admin}product`,
    "addproduct": `${admin}product`,
    "updateProduct": (productId: string) => { return `${admin}product/${productId}` },
    "updateproductStatus": (productId: string) => { return `${admin}/product-status/${productId}` },
    "uploadProductimage": `${admin}upload-product`,
    "addComment": (orderId: string) => { return `${admin}addComments/${orderId}` },
  },
  "gourmetOrder": {
    "list": `${admin}order`,
    "orderDetails": (orderId: string) => { return `orderDetails?orderId=${orderId}` },
    "createQuotationOrder": `${lalamove}createQuotationOrder`,
    "comanImage": (productId: string) => { return `${admin}product-images/${productId}` },
    "deleteImage": `${admin}delete-img`,
    "partialrefund": `${admin}partial-refund`,

  },
  // 
  "company": {
    "list": (page: string, limit: number, search: string) => { return `${admin}company?page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') },
    "petz": `${admin}pet`,
    "addCompany": `${admin}company`,
    "editCompany": (companyId: string) => { return `${admin}company/${companyId}` },
    "changeStatus": (companyId: string) => { return `${admin}company-status/${companyId}` },
    "serviceCategory": `${frontEnd}serviceCategory`
  },
  "admin": {
    "adminList": (page: number, limit: number, search: string, roleType: string, bearingfilter: string, kycfilter: String) => { return `${admin}admin?page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') + (roleType ? `&role=${roleType}` : '') + (bearingfilter ? `&bearingfilter=${bearingfilter}` : '') + (kycfilter ? `&kycfilter=${kycfilter}` : '') },
    "addAdmin": `${admin}admin`,
    "updateAdmin": (adminId: string) => { return `${admin}admin/${adminId}` },
    "uploadDocuments": `${admin}upload-company-documents`,
    "adminStatus": (adminId: string) => { return `${admin}admin-status/${adminId}` },
    "changePassword": (adminId: string) => { return `${admin}changePassword/${adminId}` },
    "favStatus": (adminId: string) => { return `${admin}admin-fav/${adminId}` },
    "companycommission": `${admin}company-commission`,
    "auropayId": `${admin}company-auropayId`,
    "kyc": `${admin}company-kyc`,
  },
  "static": {
    "aboutUs": `${admin}about-us`,
    "termsConditions": `${admin}terms-conditions`,
    "termsConditionsSupplier": `${admin}terms-conditions-supplier`,
    "PrivacyPolicy": `${admin}privacy-policy`,
  },
  "booking": {
    "list": (page: number, limit: number, search: string, status: string, payment: string) => { return `${admin}booking-list?page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') + (status ? `&status=${status}` : '') + (payment ? `&paymentStatus=${payment}` : '') },
    "bookingInvoice": (bookingId: string) => { return `${admin}booking-invoice/${bookingId}` },
    "refund": `${admin}booking-partial-refund`,
    "addComment": (bookingId: string) => { return `${admin}addComment/${bookingId}` },
  },
  "apkVersion": {
    "version": `${admin}appVersion`,
    "update": (versionId: string) => { return `${admin}updateVersion/${versionId}` }
  },
  "category": {
    "list": (page: number, limit: number, search: string, status: string) => { return `${admin}category?page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') + (status ? `&status=${status}` : '') },
    "add": `${admin}category`,
    "update": (category_id: string) => { return `${admin}category-update/${category_id}` },
    "status": (category_id: string) => { return `${admin}category-status/${category_id}` },
    "product": `${admin}product-type`

  },
  "subCategory": {
    "list": (page: number, limit: number, search: string, status: string) => { return `${admin}category?page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') + (status ? `&status=${status}` : '') },
    "add": `${admin}sub-category`,
    "update": (subCategory_id: string) => { return `${admin}sub-category-update/${subCategory_id}` },
    "status": (subCategory_id: string) => { return `${admin}sub-category-status/${subCategory_id}` }

  },
  "promo": {
    "list": (page: number, limit: number, search: string, status: string) => { return `${admin}promocode?page=${page}&limit=${limit}` + (search ? `&search=${search}` : '') + (status ? `&status=${status}` : '') },
    "add": `${admin}promocode`,
    "update": (promocode_id: string) => { return `${admin}promocode/${promocode_id}` },
    "status": (promocode_id: string) => { return `${admin}promocode-status/${promocode_id}` },
    // "sendPromocode":`${admin}/send-promocode`
    "sendPromocode": `${admin}send-promocode`,


  },
  "petLife": {
    "list": `${admin}pet-life-stage`
  },
  "date": {
    "change": (id: string) => { return `${admin}delivery-date/${id}` },
  },
  "bulk": {
    "add": `${admin}bulk-add`,
  },
  "lalamoveService": {
    "List": `${admin}lalamove-service-list`
  },
  "grooming": {
    "list": (page: number, limit: number, search: string, serviceCategoryId: string) => { return `${admin}grooming-list?page=${page}&limit=${limit}&serviceCategoryId=${serviceCategoryId}` + (search ? `&search=${search}` : '') },
    "details": (serviceCategoryId: string) => { return `${admin}grooming-detail?supplierId=${serviceCategoryId}` },

  },
  "staff": {
    "list": (page: number, limit: number, search: string, supplierId: string) => { return `${admin}staff-list?page=${page}&limit=${limit}&supplierId=${supplierId}` + (search ? `&search=${search}` : '') },
    "details": (staffId: string) => { return `${admin}staff-details?staffId=${staffId}` },

  },
  // get4EyeResultDetails
  "Order": {
    "Eye": (orderId: string, productId) => { return `${admin}get4EyeResultDetails?` + (orderId ? `&orderId=${orderId}` : '') + (productId ? `&productId=${productId}` : '') },
    "add": `${admin}add4EyeResult`,
    "update": (resultiId: string) => { return `${admin}updateResult/${resultiId}` },
    "OrserStatus": `${admin}order-status`,
  },
  "driver": {
    "Details": (trackingId: string, driverId: string) => { return `${lalamove}driverDetails?trackingId=${trackingId}&driverId=${driverId}` },
  },
  "approval": {
    "Update": (testResult: string,) => { return `${admin}updateApproval/${testResult}` },
  },
  "kyc": {
    'link': `${admin}kyc-link`,
    'status': `${admin}kyc-status`,
  }

}
