<!-- <div class="page-content"> -->
    <!-- <div class="container-fluid"> -->
        <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
              <div class="page-title-right">
                <ol class="breadcrumb m-0">
                  <!-- <li class="breadcrumb-item active">{{aboutUsData?.contentList?.title | titlecase}}</li> -->
                </ol>
              </div>
            </div>
          </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body" >
                        <!-- <h4 class="card-title"></h4> -->
                        <p class="card-title-desc" [innerHTML]="data?.contentList?.description">{{data?.contentList?.description}}</p>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
  <!-- </div> -->


  
  