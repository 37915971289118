import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit {
  private dataSubject = new Subject<any>();
  isOpen=false;
  kycdata:any;

  constructor(
    private commonService:CommonService,
    private router:Router
    // private router:Router,
  ) { }

  ngOnInit(): void {
    // this.getKycStatus()
  }

  getKycStatus(){
    this.commonService.kycStatus().then((res:any)=>{
      if(res.code ==200){
        this.kycdata=res
        this.isOpen=true;
      }
    })
  }

  // naviGateTo(){
  //   if(this.kycdata.kycstatus=='PENDING'){
  //     this.commonService.setNewInfo({ "kyc": false });
  //     this.router.navigate(['/login'])
  //   }else{
  //     this.router.navigate(['/login']);
  //     this.commonService.setNewInfo({ "kyc": true });
  //   }
  // }

  naviGateTo(){
    localStorage.clear();
    this.router.navigate(['/login'])
  }

}
