import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDecimal]'
})
export class DecimalDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    // Define a regular expression to match valid price formats
    const pricePattern = /^(\d+)?(\.\d{0,2})?$/;
    
    if (!pricePattern.test(inputValue)) {
      // If the input does not match the valid format, reset the input value
      input.value = inputValue.slice(0, -1); // Remove the last character
    }
  }

}
