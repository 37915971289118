<div class="popup-overlay kycModal" >
    <div class="popup-container">
        <div class="popup-header">
            <span class="popup-title">Bank KYC</span>
            <button class="popup-close-btn" ></button>
        </div>
        <div class="popup-content">
            <p style="font-size: initial;">Your KYC has been successfully submitted. And please relogin your company account.</p>
            <div class="popup-buttons">
                <button class="btn btn-no" (click)="naviGateTo()">Relogin</button>
            </div>
        </div>
    </div>
</div>