import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  data:any;
  constructor(
    private authService:AuthService
  ) { }

  ngOnInit(): void {
    this.privacyPolicyes();
  }


  privacyPolicyes(){
    this.authService.privacyPolicy().then((res)=>{
      if(res){
        this.data=res
      }
      
    })
  }

}
